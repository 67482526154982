import { useParams } from '@remix-run/react';
import {
  type ReactElement,
  type ReactNode,
  useEffect,
  useLayoutEffect,
} from 'react';

import { useInitLogger } from '../../hooks/useInitLogger';
import { useInstance } from '../../hooks/useInstance';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { useTaskQueue } from '../../hooks/useTaskQueue';
import { useVenueMode } from '../../hooks/useVenueMode';
import { type ClientType, VenueMode } from '../../types';
import { uuidv4 } from '../../utils/common';
import { installUserActivationPolyfill } from '../../utils/user-activation';
import { ClockProvider, useClock } from '../Clock';
import { DeviceContextProvider } from '../Device';
import { type IVideoStreamMixer } from '../Device/video-stream-mixer';
import { FirebaseContextProvider, firebaseService } from '../Firebase';
import { FirebaseUtils } from '../Firebase/utils';
import { GameHostingProvider } from '../Game/GameHostingProvider';
import { useLocalLoadedGamePack } from '../Game/GamePlayStore';
import { useIsLiveGamePlay } from '../Game/hooks';
import { PlaybackInfoProvider } from '../Game/Playback/PlaybackInfoProvider';
import { MultipleUserInstancesProvider } from '../MutipleInstances';
import { PlayerStoreProvider } from '../Player';
import { ProvidersList } from '../ProvidersList';
import { StreamSessionProvider } from '../Session';
import { useSoundEffect } from '../SFX';
import { StageProvider } from '../Stage';
import { TeamOpsProvider } from '../TeamAPI/TeamV1';
import { useUser } from '../UserContext';
import { VenueBackgroundManager } from '../VenueBackgroundManager';
import { VideoEffectsSettingsStorageProvider } from '../VideoEffectsSettings/Storage';
import {
  type VideoEffectsSettings,
  type VideoEffectsSettingsStorage,
} from '../VideoEffectsSettings/types';
import { VenueEventProvider } from './VenueEventProvider';
import {
  ToggleAudioEnabled,
  VenuePlaygroundContextProvider,
} from './VenuePlaygroundProvider';
import { useVenueAPI } from './VenueProvider';

const VenueSFXSetup = (): JSX.Element => {
  const venueMode = useVenueMode();
  const isLiveGameplay = useIsLiveGamePlay();
  const { play, stop } = useSoundEffect('startStream');

  useEffect(() => {
    if (venueMode !== VenueMode.Game || !isLiveGameplay) return;
    play();
    return () => stop();
  }, [play, stop, venueMode, isLiveGameplay]);

  return <></>;
};

export function VenueSettingsWatcher() {
  const isCoordinator = useIsCoordinator();
  if (!isCoordinator) return null;
  return <VenueSettingsWatcherInternal />;
}

function VenueSettingsWatcherInternal() {
  const venueAPI = useVenueAPI();
  const loadedGamePack = useLocalLoadedGamePack();
  const { addTask } = useTaskQueue({ shouldProcess: true });

  useEffect(() => {
    if (!loadedGamePack?.id) {
      addTask(() => venueAPI.updateDerivedVenueSettings(null));
    } else {
      addTask(() =>
        venueAPI.deriveVenueSettings({ gamePackId: loadedGamePack.id })
      );
    }
  }, [addTask, loadedGamePack?.id, venueAPI]);
  return null;
}

export const VenueSetup = (): JSX.Element => {
  return (
    <>
      <UserActivationPolyfill />
      <ToggleAudioEnabled />
      <VenueSFXSetup />
      <VenueBackgroundManager />
      <VenueSettingsWatcher />
    </>
  );
};

export function VenueBootstrap(props: {
  venueId: string;
  clientType: ClientType;
  children?: ReactNode;
  providers?: (ReactElement | null)[];
  deviceDisabled?: boolean;
  vesPersist?: boolean;
  vesDefaultValues?: Partial<VideoEffectsSettings>;
  mixer: IVideoStreamMixer;
  vesStore: VideoEffectsSettingsStorage;
}): JSX.Element {
  const { venueId, clientType } = props;
  const recoveryConfig = useInstance(() => FirebaseUtils.RecoveryConfig());
  const clientId = useInstance(() => uuidv4());

  const providers = [
    <DeviceContextProvider
      profileIndex='720p'
      noop={props.deviceDisabled}
      mixer={props.mixer}
    />,
    <VenuePlaygroundContextProvider
      myClientId={clientId}
      myClientType={clientType}
    />,
    <FirebaseContextProvider
      svc={firebaseService}
      recoveryConfig={recoveryConfig}
    />,
    <ClockProvider />,
    <PlayerStoreProvider venueId={venueId} />,
    <MultipleUserInstancesProvider venueId={venueId} />,
    <StreamSessionProvider
      venueId={venueId}
      recoveryTimeoutMs={recoveryConfig.totalMs}
    />,
    <StageProvider venueId={venueId} firebaseService={firebaseService} />,
    <VideoEffectsSettingsStorageProvider store={props.vesStore} />,
    <VenueEventProvider venueId={venueId} />,
    <VenueLoggerSetup
      venueId={venueId}
      clientId={clientId}
      clientType={clientType}
    />,
    <GameHostingProvider venueId={venueId} />,
    <TeamOpsProvider />,
    <PlaybackInfoProvider venueId={venueId} />,
    ...(props.providers ?? []),
  ];

  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}

function VenueLoggerSetup(props: {
  venueId: string;
  clientId: string;
  clientType: ClientType;
  children?: ReactNode;
}): JSX.Element | null {
  const { venueId, clientId, clientType } = props;
  const user = useUser();
  const clock = useClock();

  const inited = useInitLogger({
    uid: user.id,
    venueId,
    clientId,
    clientType,
    getTimeMs: clock.now,
  });

  if (!inited) return null;
  return <>{props.children}</>;
}

export function useParamVenueId() {
  const { vid } = useParams<'vid'>();
  return vid ?? '';
}

function UserActivationPolyfill() {
  useLayoutEffect(() => {
    installUserActivationPolyfill();
  }, []);
  return null;
}
