import { useEffect } from 'react';

import { type User } from '../types/user';
import { type Venue } from '../types/venue';
import { makeTitle } from '../utils/common';

export function usePageTitle(
  user: User,
  venue: Venue | null,
  isHost = false
): void {
  useEffect(() => {
    if (user?.username && venue?.displayName) {
      document.title = makeTitle(
        `${isHost ? '🎤' : ''} ${user.username} - ${venue.displayName}`
      );
    }
  }, [venue?.displayName, user?.username, isHost]);
}
