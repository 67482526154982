import { type ReactNode } from 'react';

import { ProvidersList } from '../../ProvidersList';
import { AIChatProvider } from './AIChat';
import { SubmissionStatusProvider } from './Common/GamePlay/SubmissionStatusProvider';
import { DrawingPromptProvider } from './DrawingPrompt';
import { GuessWhoProvider } from './GuessWho/GuessWhoProvider';
import { HeadToHeadProvider } from './HeadToHead';
import { HiddenPictureProvider } from './HiddenPicture/HiddenPictureProvider';
import { IcebreakerProvider } from './Icebreaker';
import { JeopardyBlockProvider } from './Jeopardy/JeopardyBlockProvider';
import { MemoryMatchProvider } from './MemoryMatch';
import { OverRoastedProvider } from './OverRoasted';
import { PuzzleProvider } from './Puzzle';
import { RoundRobinProvider } from './RoundRobinQuestion';
import { SpotlightBlockProvider } from './Spotlight/SpotlightBlockProvider';
import { SpotlightV2Provider } from './SpotlightV2/SpotlightBlockV2Provider';
import { TeamRelayProvider } from './TeamRelay/Context';

export function BlockProviders(props: { children?: ReactNode }): JSX.Element {
  const providers = [
    <SubmissionStatusProvider />,
    <TeamRelayProvider />,
    <MemoryMatchProvider />,
    <PuzzleProvider />,
    <RoundRobinProvider />,
    <OverRoastedProvider />,
    <SpotlightV2Provider />,
    <DrawingPromptProvider />,
    <HiddenPictureProvider />,
    <AIChatProvider />,
    <IcebreakerProvider />,
    <GuessWhoProvider />,
    <JeopardyBlockProvider />,
    <HeadToHeadProvider />,
    <SpotlightBlockProvider />,
  ];
  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}
