import { ref } from 'valtio';

import { GameSessionUtil } from '@lp-lib/game';

import { nullOrUndefined } from '../../../../utils/common';
import { rsCounter, rsInfrequent } from '../../../../utils/rstats.client';
import { runAfterFramePaint } from '../../../../utils/runAfterFramePaint';
import { firebaseService } from '../../../Firebase';
import { type NarrowedWebDatabaseReference } from '../../../Firebase/types';
import {
  type FirebaseRefs,
  type GameSession,
  type GameSessionOndState,
  type GameSessionStore,
  gameSessionStore,
  initialState,
} from '../gameSessionStore';
import { getDataPath, resetOnDPreparing } from './core';
import { log } from './shared';

type Disposer = () => Promise<void>;

const register = async <
  K extends keyof Pick<
    GameSessionStore,
    'ondState' | 'controls' | 'detailScores' | 'scoreSummary' | 'teamData'
  >,
  V extends keyof Omit<FirebaseRefs, 'session' | 'playerData'>
>(
  refKey: K & V,
  myTeamId?: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Disposer> => {
  function buildDeregister(
    ref: NarrowedWebDatabaseReference<unknown>
  ): Disposer {
    return async () => {
      ref.off();
      gameSessionStore[refKey] = initialState[refKey];
      gameSessionStore.refs[refKey] = null;
    };
  }

  if (gameSessionStore.refs[refKey]) {
    const ref = gameSessionStore.refs[
      refKey
    ] as NarrowedWebDatabaseReference<unknown>;
    return buildDeregister(ref);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = firebaseService.prefixedRef<any>(getDataPath(refKey, myTeamId));
  const snap = await ref.get();

  if (snap.exists()) {
    gameSessionStore[refKey] = snap.val();
  }

  // at this point, we've read the latest snapshot, and the initial value for the ref should be considered as "received".
  gameSessionStore.refsInitialReceived[refKey] = true;

  ref.on('value', (snap) => {
    rsCounter(`gss-on-value-paint-${refKey}-ms`)?.start();

    if (snap.exists()) {
      gameSessionStore[refKey] = snap.val();
    } else {
      gameSessionStore[refKey] = initialState[refKey];
    }
    runAfterFramePaint(() => {
      rsCounter(`gss-on-value-paint-${refKey}-ms`)?.end();
      rsInfrequent(`gss-on-value-paint-${refKey}-ms`);
    });
  });

  gameSessionStore.refs[refKey] = ref;

  return buildDeregister(ref);
};

async function recovery(
  gameSessionRef: NarrowedWebDatabaseReference<GameSession>,
  ondStateRef: NarrowedWebDatabaseReference<GameSessionOndState>
) {
  const remoteGameSession = (await gameSessionRef.get()).val();
  if (!remoteGameSession) return;

  gameSessionStore.session = remoteGameSession;
  gameSessionStore.refsInitialReceived.session = true;

  // recovery from firebase
  {
    const remoteOndState = (await ondStateRef.get()).val();
    gameSessionStore.ondState = remoteOndState;
    gameSessionStore.refsInitialReceived.ondState = true;
    const blockProgressSec = remoteOndState?.blockProgressSec ?? 0;
    const gameProgressSec = remoteOndState?.sessionProgressSec ?? 0;

    const map = GameSessionUtil.StatusMapFor(
      remoteGameSession.blockSession?.block
    );

    const gameEndState = map?.gameEnd;
    const gameSessionState = remoteGameSession.status;
    const statesValid =
      map &&
      !nullOrUndefined(gameEndState) &&
      !nullOrUndefined(gameSessionState);

    // Note(jialin): we restart the block from paused game if the core game is not played,
    // otherwise, just continue from where it is paused.
    const restartBlock =
      remoteOndState?.state === 'paused' &&
      blockProgressSec > 0 &&
      statesValid &&
      gameSessionState < gameEndState;

    log.info(`check restart block: ${restartBlock}`, {
      blockId: remoteGameSession.blockSession?.block?.id,
      blockType: remoteGameSession.blockSession?.block?.type,
      gameEndState,
      gameSessionState,
      blockProgressSec,
      gameProgressSec,
      remoteOndState: remoteOndState?.state,
    });

    if (restartBlock) {
      // note(falcon): really, this should call resetBlock, which is
      // responsible for clearing all data. for example, we could roll back
      // previously submitted answers, reset submission states, etc. however,
      // resetBlock needs gameSessionStore.refs to be initialized. but that
      // happens _after_ recovery.
      gameSessionStore.session.status = map.loaded;
      if (gameSessionStore.ondState) {
        gameSessionStore.ondState.gamePlayVideoProgress = null;
        gameSessionStore.ondState.blockEndingSec = 0;
        gameSessionStore.ondState.blockRemainderMs = 0;
        gameSessionStore.ondState.blockProgressSec = 0;
        gameSessionStore.ondState.waitModeInfo = null;
        gameSessionStore.ondState.waitModeExtSignal = null;
      }
      await gameSessionRef.update({
        status: gameSessionStore.session.status,
      });
      await ondStateRef.update({
        gamePlayVideoProgress: null,
        blockEndingSec: 0,
        blockRemainderMs: 0,
        blockProgressSec: 0,
        waitModeInfo: null,
        waitModeExtSignal: null,
        // Reset visible time since we're restarting the block. It's an edge
        // case, but it looks really visibly wrong if you refresh while hosting
        // and see the exact same elapsed time but the block has restarted.
        sessionProgressSec: gameProgressSec - blockProgressSec,
      });
    } else {
      await ondStateRef.update({
        sessionProgressSec: gameProgressSec,
      });
    }
  }
}

function initSessionRef(): [
  NarrowedWebDatabaseReference<GameSession>,
  Disposer
] {
  const ref = firebaseService.prefixedRef<GameSession>(getDataPath('session'));

  ref.on('value', (snap) => {
    const val = snap.val();
    if (val) {
      gameSessionStore.session = val;
    }
    gameSessionStore.refsInitialReceived.session = true;
  });

  return [
    ref,
    async () => {
      ref.off();
      gameSessionStore.session = initialState.session;
    },
  ];
}

export type GameSessionInitConfig = {
  initialSetup: false | { isLiveGame: boolean };
  isController: boolean;
  isCohost?: boolean;
  terminateSession: () => Promise<void>;
};

export const init = async (
  venueId: string,
  config: GameSessionInitConfig
): Promise<Disposer> => {
  log.info('init game session');
  const { initialSetup, isController, isCohost } = config;
  gameSessionStore.venueId = venueId;
  gameSessionStore.isController = isController;
  gameSessionStore.terminateSession = ref(config.terminateSession);

  const disposers: Disposer[] = [];
  disposers.push(async () => {
    gameSessionStore.venueId = null;
    gameSessionStore.terminateSession = undefined;
  });

  if (!gameSessionStore.refs.session) {
    const [sessionRef, disposeSessionRef] = initSessionRef();
    disposers.push(disposeSessionRef);

    // The code in initialSetup are onetime setup, no need dispose.
    if (initialSetup) {
      const ondStateRef = firebaseService.prefixedRef<GameSessionOndState>(
        getDataPath('ondState')
      );

      const snap = await sessionRef.get();
      const val = snap.val();
      if (val) {
        await recovery(sessionRef, ondStateRef);
      }

      await sessionRef.update({
        isLive: initialSetup.isLiveGame,
      });

      await resetOnDPreparing();
    }

    gameSessionStore.refs.session = sessionRef;
    disposers.push(async () => {
      gameSessionStore.refs.session = null;
    });
  }

  disposers.push(await register('ondState'));
  disposers.push(await register('scoreSummary'));
  if (isController) {
    disposers.push(await register('controls'));
  }
  if (isController || isCohost) {
    disposers.push(await register('detailScores'));
  }

  gameSessionStore.isController = isController;
  gameSessionStore.initialized = true;

  disposers.push(async () => {
    gameSessionStore.isController = false;
    gameSessionStore.initialized = false;
  });

  // dispose all
  return async () => {
    if (!gameSessionStore.initialized) return;
    log.info('dispose game session');
    for (const disposer of disposers) {
      await disposer();
    }
  };
};
