import { useEffect } from 'react';

import {
  useIsParticipantStoreInited,
  useParticipantJoin,
  useParticipantLeave,
  useUpdateParticipant,
} from '../components/Player';
import { useMyI18nSettings } from '../components/Settings/useMyI18nSettings';
import { useUserStates } from '../components/UserContext';
import config from '../config';
import { type ClientType, RoleUtils, type User } from '../types/user';
import { useLiveCallback } from './useLiveCallback';
import { useMyInstance } from './useMyInstance';
import { useUnload } from './useUnload';

// Note(jialin): Most of the parameters that used to create the participant are "immutable".
// They won't change until the user leaves. We also don't want to re-join the user because
// the value changes. In order to avoid that, we wrap the "mutable" parameters with useRef,
// so the participant will be only initialized once.
function useInitParticipant(
  clientId: string,
  clientType: ClientType,
  user: User,
  cohost: boolean
) {
  const { joined } = useUserStates();
  const isParticipantStoreInited = useIsParticipantStoreInited();
  const participantJoin = useParticipantJoin();
  const participantLeave = useParticipantLeave();

  const join = useLiveCallback(() => {
    // only allow cohost for admin and Luna Park
    const canCohost =
      RoleUtils.isAdmin(user) ||
      user.organizer?.orgId === config.misc.lunaParkOrgId;

    participantJoin({
      id: user.id,
      clientId: clientId,
      clientType: clientType,
      username: user.username,
      firstName: user.organizer?.firstName,
      lastName: user.organizer?.lastName,
      userAgent: navigator.userAgent,
      orgId: user.organizer?.orgId,
      cohost: canCohost && cohost,
    });
  });

  const leave = useLiveCallback(() => {
    participantLeave(clientId, true);
  });

  useEffect(() => {
    if (!user.id || !isParticipantStoreInited || !joined) return;
    join();
    return () => leave();
  }, [isParticipantStoreInited, join, joined, leave, user.id]);

  useUnload(() => {
    leave();
  });
}

function useSyncParticipant(user: User, icon?: string) {
  const { audio, video, lite } = useUserStates();
  const me = useMyInstance();
  const updateParticipant = useUpdateParticipant();
  useEffect(() => {
    if (!me?.clientId) return;
    updateParticipant(me.clientId, { username: user.username });
  }, [me?.clientId, updateParticipant, user.username]);

  useEffect(() => {
    if (!me?.clientId) return;
    updateParticipant(me.clientId, { video });
  }, [video, me?.clientId, updateParticipant]);

  useEffect(() => {
    if (!me?.clientId) return;
    updateParticipant(me.clientId, { audio });
  }, [audio, me?.clientId, updateParticipant]);

  useEffect(() => {
    if (!me?.clientId) return;
    updateParticipant(me.clientId, { lite });
  }, [me?.clientId, lite, updateParticipant]);

  useEffect(() => {
    if (!me?.clientId) return;
    const val = icon || user.organizer?.icon;
    if (!val) return;
    updateParticipant(me.clientId, { icon: val });
  }, [me?.clientId, updateParticipant, icon, user.organizer?.icon]);
}

function useSyncParticipantLocale() {
  const me = useMyInstance();
  const { i18nSettings: localeSettings } = useMyI18nSettings();
  const updateParticipant = useUpdateParticipant();

  useEffect(() => {
    if (!me?.clientId || !localeSettings?.value?.voiceOverLocale) return;
    updateParticipant(me.clientId, {
      voiceOverLocale: localeSettings?.value?.voiceOverLocale,
    });
  }, [localeSettings?.value?.voiceOverLocale, me?.clientId, updateParticipant]);
}

export function useUserWatcher(
  clientId: string,
  clientType: ClientType,
  user: User,
  icon?: string,
  cohost = false
): void {
  useInitParticipant(clientId, clientType, user, cohost);
  useSyncParticipant(user, icon);
  useSyncParticipantLocale();
}
