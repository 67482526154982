import { useEffect } from 'react';

import {
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../components/Firebase/Context';
import {
  useInitParticipantStore,
  useParticipantsGetter,
} from '../components/Player';
import { useInitTeamStore } from '../components/TeamAPI/TeamV1';
// eslint-disable-next-line no-restricted-imports
import { useInitNotificationStore } from '../store/notificationSlice';
import { useStatsAwareTaskQueue } from './useTaskQueue';

export function useInitReduxSlice(
  clientId: string,
  venueId: string | undefined
): void {
  const firebaseConnected = useIsFirebaseConnected();
  const { emitter } = useFirebaseContext();
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-redux-init-ms',
  });
  const initParticipantStore = useInitParticipantStore();
  const initTeamStore = useInitTeamStore();
  const participantsGetter = useParticipantsGetter();
  const initNotificationStore = useInitNotificationStore();

  useEffect(() => {
    if (!firebaseConnected || !venueId) return;

    let disposers: (() => void)[] = [];
    addTask(async function init() {
      disposers = await Promise.all([
        initParticipantStore(),
        initTeamStore(venueId, participantsGetter),
        initNotificationStore(venueId, clientId),
      ]);
    });

    return () => {
      addTask(async function dispose() {
        disposers.forEach((disposer) => disposer());
      });
    };
  }, [
    addTask,
    clientId,
    emitter,
    firebaseConnected,
    initNotificationStore,
    initParticipantStore,
    initTeamStore,
    participantsGetter,
    venueId,
  ]);
}
