import { useIsController } from '../../../../../hooks/useMyInstance';
import {
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../../../../Firebase';
import { ProvidersList } from '../../../../ProvidersList';
import { useIsStreamSessionAlive } from '../../../../Session';
import { useVenueId } from '../../../../Venue/VenueProvider';
import { OverRoastedGameControlProvider } from './OverRoastedGameControlProvider';
import { OverRoastedGamePlayProvider } from './OverRoastedGamePlayProvider';
import { OverRoastedSharedProvider } from './OverRoastedSharedProvider';

export function OverRoastedProvider(props: {
  children?: React.ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const isController = useIsController();
  const firebaseConnected = useIsFirebaseConnected();
  const isSessionAlive = useIsStreamSessionAlive();
  const ready = firebaseConnected && isSessionAlive;

  const providers = [
    <OverRoastedSharedProvider
      venueId={venueId}
      firebaseService={svc}
      ready={ready}
    />,

    isController ? (
      <OverRoastedGameControlProvider
        venueId={venueId}
        firebaseService={svc}
        ready={ready}
      />
    ) : null,

    <OverRoastedGamePlayProvider
      venueId={venueId}
      firebaseService={svc}
      ready={ready}
    />,
  ];

  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}
