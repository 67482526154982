import { useEffect, useState } from 'react';

import {
  createSilentAudioElement,
  unlockAudioContext,
} from '../services/audio/audio-context';
import { getFeatureQueryParam } from './useFeatureQueryParam';

const unlockAudioEnabled = getFeatureQueryParam('ensure-unlocked-audio');

export function useEnsureUnlockedAudioContext(): void {
  useEffect(() => {
    if (!unlockAudioEnabled) return;
    const handler = async () => {
      if (!(await unlockAudioContext())) {
        document.body.addEventListener('pointermove', handler, { once: true });
        document.body.addEventListener('pointerdown', handler, { once: true });
      }
    };

    document.body.addEventListener('pointermove', handler, { once: true });
    document.body.addEventListener('pointerdown', handler, { once: true });
  }, []);
}

export function useEnsureUnlockedAudioElement() {
  const [unlocked, setUnlocked] = useState(false);

  useEffect(() => {
    if (!unlockAudioEnabled || unlocked) return;
    const handler = async () => {
      const audio = createSilentAudioElement();
      try {
        await audio.play();
        setUnlocked(true);
      } catch (e) {
        if (unlocked) return;
        document.body.addEventListener('pointermove', handler, { once: true });
        document.body.addEventListener('pointerdown', handler, { once: true });
      }
    };

    document.body.addEventListener('pointermove', handler, { once: true });
    document.body.addEventListener('pointerdown', handler, { once: true });
  }, [unlocked]);

  return unlocked;
}
