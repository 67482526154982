import { useEffect, useLayoutEffect, useState } from 'react';

import { useStreamSessionId } from '../components/Session';
import logger from '../logger/logger';
import { type ClientType } from '../types';
import { getFeatureQueryParam } from './useFeatureQueryParam';

export function useInitLogger(props: {
  uid: string;
  venueId: string;
  clientId: string;
  clientType: ClientType;
  getTimeMs: () => number;
}): boolean {
  const { uid, venueId, clientId, clientType, getTimeMs } = props;
  const [inited, setInited] = useState(false);

  useLayoutEffect(() => {
    logger.verbose(getFeatureQueryParam('verbose-local-logging'));
    logger.updateSharedMeta({
      uid: uid,
      venueId: venueId,
      clientId: clientId,
      clientType: clientType,
    });
    logger.setTimeMsGetter(getTimeMs);
    logger.scoped('device').info('DeviceInfo', {
      userAgent: navigator.userAgent,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
    });
    return () => {
      logger.updateSharedMeta({
        clientId: undefined,
        clientType: undefined,
      });
    };
  }, [clientId, clientType, getTimeMs, uid, venueId]);

  useEffect(() => {
    // NOTE: if you check for !!uid, this will likely never init because it is
    // set to non-empty-string from a descendent further down the tree :(
    setInited(true);
    return () => {
      setInited(false);
    };
  }, []);

  return inited;
}

export function useUpdateLoggerWithStreamSessionId(): void {
  const sessionId = useStreamSessionId();
  useLayoutEffect(() => {
    logger.updateSharedMeta({
      sessionId,
    });

    return () => {
      logger.updateSharedMeta({
        sessionId: undefined,
      });
    };
  }, [sessionId]);
}
