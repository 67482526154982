import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { useStatsAwareTaskQueue } from '../../../../hooks/useTaskQueue';
import { useIsFirebaseConnected } from '../../../Firebase';
import { useStageContext, useStageControlAPI } from '../Provider';

export function useInitStage(): void {
  const firebaseConnected = useIsFirebaseConnected();
  const stageControl = useStageControlAPI();
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-init-stage-ms',
  });

  useEffect(() => {
    if (!firebaseConnected) return;
    addTask(async function init() {
      await stageControl.init();
    });
    return () => {
      addTask(async function reset() {
        stageControl.reset();
      });
    };
  }, [addTask, firebaseConnected, stageControl]);
}

export function useIsStageInited(): boolean {
  const ctx = useStageContext();
  return useSnapshot(ctx.state).inited;
}
