import { useCallback, useEffect } from 'react';
import { useLatest } from 'react-use';

import {
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../components/Firebase';
import { init as initGameSession } from '../components/Game/store';
import { type GameSessionInitConfig } from '../components/Game/store/gameSessionActions/init';
import { useAmICohost } from '../components/Player';
import {
  useIsStreamSessionAliveOrAborted,
  useIsStreamSessionInited,
  useStreamSessionControlAPI,
} from '../components/Session';
import { useVenueId } from '../components/Venue/VenueProvider';
import logger from '../logger/logger';
import { useMyInstance } from './useMyInstance';
import { useStatsAwareTaskQueue } from './useTaskQueue';

const log = logger.scoped('game-session');

export function useInitGameSession(
  options: Omit<
    GameSessionInitConfig,
    'terminateSession' | 'emitter' | 'gamePlayStore'
  >
): void {
  const { isController, initialSetup } = options;
  const firebaseConnected = useIsFirebaseConnected();
  const isStreamSessionInited = useIsStreamSessionInited();
  const isSessionLiveOrAborted = useLatest(useIsStreamSessionAliveOrAborted());
  const { stop: stopStreamSession } = useStreamSessionControlAPI();
  const me = useMyInstance();
  const myClientId = me?.clientId;
  const venueId = useVenueId();
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-init-game-session-ms',
  });
  const isCohost = useAmICohost();

  const terminateSession = useCallback(async () => {
    if (!isSessionLiveOrAborted.current || !myClientId) return;
    await stopStreamSession();
  }, [isSessionLiveOrAborted, myClientId, stopStreamSession]);

  const ready = firebaseConnected && isStreamSessionInited && !!venueId && !!me;
  const latastReady = useLatest(ready);
  const { emitter } = useFirebaseContext();

  useEffect(() => {
    if (!ready) return;
    let disposer: () => Promise<void>;
    addTask(async function init() {
      // double check the latest ready state
      if (!latastReady.current) return;
      try {
        disposer = await initGameSession(venueId, {
          initialSetup,
          isController,
          isCohost,
          terminateSession,
        });
      } catch (error) {
        log.error('initGameSession error', error);
      }
    });
    return () => {
      addTask(async function reset() {
        if (disposer) await disposer();
      });
    };
  }, [
    addTask,
    emitter,
    initialSetup,
    isCohost,
    isController,
    latastReady,
    ready,
    terminateSession,
    venueId,
  ]);
}

export function useInitStreamSession(enabled: boolean): void {
  const firebaseConnected = useIsFirebaseConnected();
  const { init: initStreamSession, reset: resetStreamSession } =
    useStreamSessionControlAPI();
  useEffect(() => {
    if (!firebaseConnected) return;
    log.info('init stream session');
    initStreamSession(enabled);
    return () => {
      log.info('reset stream session');
      resetStreamSession();
    };
  }, [initStreamSession, enabled, resetStreamSession, firebaseConnected]);
}
