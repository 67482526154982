import { setContext, setTags, setUser } from '@sentry/remix';
import { useEffect } from 'react';

import { useStreamSessionId } from '../components/Session';
import { useMyInstance } from './useMyInstance';

export function useInitSentryContext(venueId: string | undefined): void {
  const sessionId = useStreamSessionId();
  const me = useMyInstance();

  useEffect(() => {
    if (!venueId) return;
    setContext('venue', { venueId, sessionId });
    setTags({ venueId, sessionId });

    return () => {
      setContext('venue', null);
      setTags({ venueId: undefined, sessionId: undefined });
    };
  }, [venueId, sessionId]);

  useEffect(() => {
    if (!me) return;
    setContext('participant', me);
    setTags({ clientId: me.clientId });
    setUser({
      id: me.id,
      username: me.username,
    });
    return () => {
      setContext('participant', null);
      setTags({ clientId: undefined });
      setUser({
        id: undefined,
        username: undefined,
      });
    };
  }, [me]);
}
